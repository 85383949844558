import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;
import Vant from "vant";
import "vant/lib/index.css";

import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);

import "vant/lib/index.css";
import { Button } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Divider } from "vant";
import { Overlay } from "vant";
import { Notify } from "vant";
import { Loading } from "vant";
import { Empty } from "vant";
import { Icon } from "vant";
import { Image as VanImage } from "vant";

Vue.use(VanImage);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Empty);
Vue.use(Divider);
Vue.use(Overlay);
Vue.use(Notify);
Vue.use(Loading);
Vue.use(Vant);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
