<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title" />
  </div>
</template>

<script>
export default {
  created() {
    console.log("App.vue刷新")
    if (this.$router.path !== '/') {
      this.$router.push('/')
    }
  }
}
</script>
<style>
#app {
  height: 100%;
}
</style>
